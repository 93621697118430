import { DefaultSeoProps } from "next-seo";

const title = "Lockbot 🔒";
const url = "https://lockbot.app";
const description =
  "Coordinate use of your team's shared resources, in Slack 🤝";

const seoConfig: DefaultSeoProps = {
  title,
  description,
  openGraph: {
    type: "website",
    url,
    site_name: title,
    images: [
      {
        url: "https://lockbot.app/og-square.png",
        width: 600,
        height: 600,
      },
      {
        url: "https://lockbot.app/og-rectangle.png",
        width: 1200,
        height: 628,
      },
    ],
  },
  twitter: {
    handle: "@connorads",
    cardType: "summary",
  },
  additionalMetaTags: [
    {
      property: "slack-app-id",
      content: "A0159PRF41X",
    },
  ],
};

export default seoConfig;
